import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { BiSearchAlt2 } from "react-icons/bi";
import { useState } from "react";
 import AccordianData from "../components/data/accordianData.json";
import "../components/css/career.scss";
import "../components/css/modal.scss";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../components/spinner"
import {FaFilePdf} from "react-icons/fa"
import Validations from "../components/validations"
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";




const Career = () => {

  
  const [attachments,setAttachments] = useState([])
  const [attached1,setAttached1] = useState({})
  const [attached2,setAttached2] = useState({})
  const [file1,setFile1] = useState("")
  const [validLabel1,setValidLabel] = useState(false)
  const [file2,setFile2] = useState("")
  const [validLabel2,setValidLabel2] = useState(false)
  const [selectByValue,setSelectByValue] = React.useState("")
  const [searchTerm,setSearchTerm] = useState("")
  const [isloading,setisLoading] = useState(false)
  const [changeIcon,setChangeIcon] = useState(false)
  const [changeFirstBoxName,setChangeFirstBoxName] = useState("File Format: doc, docx, image, pdf")
  const [isloading2,setisLoading2] = useState(false)
  const [changeIcon2,setChangeIcon2] = useState(false)
  const [changeFirstBoxName2,setChangeFirstBoxName2] = useState("File Format: doc, docx, image, pdf")
  const [modal, setModal] = useState(false);
  const [mainsubject,setSubject] = useState("")


  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string()
   .required("Phone is required")
   .matches(
     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
     "Only Phone and Numbers Characters are accept e:g(-)"
   ).min(7).max(13),
   location: yup.string().required("Location is required"),
    // file1: yup.mixed()
    // .test('required', "You need to provide a file", (value) =>{
    //   return value && value.length
    // } )
    // .test("fileSize", "The file is too large", (value, context) => {
    //   return value && value[0] && value[0].size <= 700000;
    // }),
  //  file2: yup.mixed()
  //  .test('required', "You need to provide a file", (value) =>{
  //    return value && value.length
  //  } )
  //  .test("fileSize", "The file is too large", (value, context) => {
  //    return value && value[0] && value[0].size <= 500000;
  //  }),
  //  .test("type", "We only support jpeg", function (value) {
  //    return value && value[0] || value[0].type === "image/jpeg";
  //  }),


  });

  const { register, handleSubmit, formState: { errors,isSubmitting }, reset,setValue } = useForm({
    resolver: yupResolver(schema),
    // defaultValues:{
    //   location: selectByValue ? selectByValue : ""
    // }
    
   
  });


  const sendEmail = async (formData) => {
  
 

  if(!file1){
    setValidLabel(true)
    return 
  }
  else if(!file2){
    setValidLabel2(true)
    return 

  }
 
  else{
    
   
    // const newAttachments = attachments.map((attachfile)=>({path:attachfile.file}))
   
    // console.log(newAttachments)
    formData.subject = mainsubject;
    formData.resume = attached1?.file
    formData.coverLetter = attached2?.file
    const attachArr = [attached1,attached2]
    const newAttachments = attachArr.map((attachfile)=>({path:attachfile.file})) 

   
    const newFormData = {...formData,attachments:[...newAttachments]}
   
    const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(newFormData)
          };
          // console.log(newFormData)

          const response = await fetch('https://api.edgeq.io/contact', requestOptions);
               const data = await response.json();
          
        
             if(data.success){
              //  console.log(data);
              toast("Form submitted successfully",{
                position: "top-center",
               autoClose: false,
               hideProgressBar: true,      
          closeOnClick: true,
        })
        
          
             setChangeIcon(false)
            setChangeFirstBoxName("File Format: doc, docx, image, pdf")
               setChangeIcon2(false)
              setChangeFirstBoxName2("File Format: doc, docx, image, pdf")
              setFile1("")
              setFile2("")

             reset()     
          
  }
    


}
  }


  const toggleModal = (_subject,city) => {
    setModal(!modal);
    setSubject(_subject)
    document.querySelector("html").style.overflowY = "hidden"
    document.querySelector("html").style.position = "fixed"
    document.querySelector("html").style.left = "0"
    document.querySelector("html").style.right = "0"
   if(selectByValue !== ""){

    setValue("location", selectByValue, {
      shouldValidate: true,
      shouldDirty: true
    })
   }  
  
  };
  
  const closePopup = ()=>{
    setModal(false);
    document.querySelector("html").style.overflowY = "auto"
    document.querySelector("html").style.position = "initial"
    document.querySelector(".apply-form").reset()
   
    
    setSearchTerm("")
  }
  
  const onAttachmentsSubmit = async (evt)=>{
    
    const attachedFile = evt.target.files[0]
    setFile1(evt.target.files[0].name)
    setisLoading(true)
    const formData = new FormData();

    formData.append("file",attachedFile)
  //   let reader = new FileReader();
  //   console.log(attachedFile)
  //  reader.onloadend = ()=>{
  //   setAttachments(reader.result)
  //    }    
  //   reader.readAsDataURL(attachedFile)
    const requestOptions = {
      method: 'POST',
    // headers: { 'Content-Type': 'multipart/form-data' },
      body:   formData

  };
  const response = await fetch('https://api.edgeq.io/file/upload', requestOptions);
  const data = await response.json();
  if(response.status === 200){
    setisLoading(false)
    setChangeIcon(true)
    setChangeFirstBoxName(attachedFile.name)
  }
 
  setAttachments([...attachments,data])
  setAttached1(data)
  setValidLabel(false)
  

}
const onAttachmentsSubmit2 = async (evt)=>{
    
  const attachedFile = evt.target.files[0]
  setFile2(evt.target.files[0].name)
  setisLoading2(true)
  const formData = new FormData();

  formData.append("file",attachedFile)
//   let reader = new FileReader();
//   console.log(attachedFile)
//  reader.onloadend = ()=>{
//   setAttachments(reader.result)
//    }    
//   reader.readAsDataURL(attachedFile)
  const requestOptions = {
    method: 'POST',
  // headers: { 'Content-Type': 'multipart/form-data' },
    body:   formData

};
const response = await fetch('https://api.edgeq.io/file/upload', requestOptions);
const data = await response.json();

if(response.status === 200){
  setisLoading2(false)
  setChangeIcon2(true)
  setChangeFirstBoxName2(attachedFile.name)
}
// console.log(data)
setAttachments([...attachments,data])
setAttached2(data)

setValidLabel2(false)

}

   
  React.useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  


 


  
  
  return (
    <Layout>
    <Seo title="EdgeQ | Career" />
    <Helmet>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Careers - EdgeQ" />
    <meta property="og:description" content="Careers Open Positions AllBangalore, IndiaSanta Clara, US Operations Data Plane Software Developer Bangalore, India &#038; Santa Clara, US See more Operations L2/L3 Infrastructure Software Developer Bangalore, India &#038; Santa Clara, US See more Operations L3 Sr Technical/Principal Engineer – 5G Bangalore, India See more Operations L2 Technical Engineer – 5G Bangalore, India &#038; Santa Clara, &hellip; Careers Read More &raquo;" />
    <meta property="og:url" content="https://edgeq.io/careers/" />
    <meta property="og:site_name" content="EdgeQ" />
    <meta property="article:modified_time" content="2021-12-09T13:01:41+00:00" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:label1" content="Est. reading time" />
    <meta name="twitter:data1" content="2 minutes" />
    </Helmet>
  
      
    <ToastContainer hideProgressBar={true}/>
   
    <section className="career-first-sec">
      <Container>
        <Row>
          <Col md={12}>
            <div className="career-heading">
              <h2 data-aos="fade-down">
                make an <span className="orange">impact</span> today
              </h2>
            </div>
          </Col>
          <Row className="main-why">
            <Col md={4} sm={6}>
              <div className="whyedge-head">
                <h3>
                  <span className="right-arrow">
                    <BsArrowRight />
                  </span>
                  Why EdgeQ...?
                </h3>
              </div>
            </Col>
            <Col md={8} sm={6}>
              <div className="whyedge-text">
                <p>
                  We have assembled a formidable team of distinguished
                  engineers, domain experts, and top graduates from the
                  world’s most selective universities to unlock what has
                  traditionally been a proprietary, expensive and complex
                  solution. EdgeQ is funded by world-renowned investors and
                  advised by notable industry luminaries.
                </p>
              </div>
            </Col>
          </Row>
          <hr className="btline"></hr>
          <Row className="main-why open_position">
            <Col md={4} sm={6}>
              <div className="whyedge-head">
                <h3>
                  <span className="right-arrow">
                    <BsArrowRight />
                  </span>
                  Open Positions
                </h3>
              </div>
            </Col>
            <Col md={8} sm={6}>
              <div className="whyedge-text">
                <p>Don't see the job you are looking for?</p>
                <p>
                  Send us your resume and we will get in touch with you when a
                  suitable position opens.
                </p>
                <div className="submit-btn">
                  <a className="content-btn" style={{display:"inline-block"}} onClick={()=>toggleModal("New Resume Submission"," ")}>
                    Submit Resume
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="btline"></hr>
        </Row>
      </Container>
    </section>

    <section className="career-second-sec">
      <Container>
        <Row>
          <Col>
            <div className="location-sec">
              <div className="Location-box">
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z" fill="black" fill-opacity="0.54"/>
</svg>

                <select name="location" onChange={(evt)=> setSelectByValue(evt.target.value)}>
               
                  <option value="" selected>All locations</option>
                  <option value="Bangalore, India">Bangalore, India</option>
                  <option value="Santa Clara, US">Santa Clara, US</option>
                  {/* <option value="Santa Clara, CA or US Remote">Santa Clara, CA or US Remote</option> */}

                </select>
              </div>
              <div className="Location-search">
                <form action="/">
                  <input
                    type="text"
                    value={searchTerm}
                    placeholder="Search"
                    name="search"
                    onChange={(evt)=>setSearchTerm(evt.target.value)}
                    
                    
                  />
                  <div className="search-icon" > 
                    <BiSearchAlt2 />
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Accordion>
              {AccordianData.filter((filterAccord)=>{
                if(searchTerm == ""){
                  if(selectByValue){
                    return filterAccord.header.mainLocation.toLowerCase().includes(selectByValue.toLowerCase()) 
                  }
                  return filterAccord
                }
                else if(filterAccord.header.mainHeading.toLowerCase().includes(searchTerm.toLowerCase())){
                  if(selectByValue){
                    return filterAccord.header.mainLocation.toLowerCase().includes(selectByValue.toLowerCase())
                  }
                  return filterAccord
                }
              }).map((accordian, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>
                      <div className="blockkk">
                        <h3>{accordian.header.mainHeading}</h3>
                        <h4>{accordian.header.mainLocation}</h4>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>{accordian.body.para1 ?? null}</p>
                      <p>{accordian.body.para2 ?? null}</p>

                      <p>{accordian.body.smpara ?? null}</p>

                      <h3>{accordian.body.subHeading1}</h3>

                      <p>{accordian.body.newpara ?? null}</p>

                      <p>{accordian.body.newpara1 ?? null}</p>
                      <b>{accordian.body.para3 ?? null}</b> 
                      <ul>
                        {accordian.body.list1.map((Accli, index) => {
                          return <li key={index}>{Accli}</li>;
                        })}
                      </ul>         


                       <b>{accordian.body.Epara ?? null}</b> 
                      <ul>
                        {accordian?.body?.Elist?.map((Accli, index) => {
                          return <li key={index}>{Accli}</li>;
                        })}
                      </ul>              

                      <h3>{accordian.body.subHeading2}</h3>
                      {accordian.body.list2 !== undefined ? (
                        <ul>
                          {accordian.body.list2.map((Accli, index) => {
                            return <li key={index}>{Accli}</li>;
                          })}
                        </ul>
                      ) : null}

                      <h3>{accordian.body.subHeading3}</h3>
                      {accordian.body.list3 !== undefined ? (
                        <ul>
                          {accordian.body.list3.map((Accli, index) => {
                            return <li key={index}>{Accli}</li>;
                          })}
                        </ul>
                      ) : null}

                      <h3>{accordian.body.subHeading4}</h3>
                      {accordian.body.list4 !== undefined ? (
                        <ul>
                          {accordian.body.list4.map((Accli, index) => {
                            return <li key={index}>{Accli}</li>;
                          })}
                        </ul>
                      ) : null}

                      <h3>{accordian.body.accsubHeading}</h3>
                      <p>{accordian.body.accpara}</p>

                      <div className="applynow">
               
                      <button className="content-btn" onClick={()=>toggleModal(accordian.header.mainHeading ,accordian.header.mainLocation)}>
                        Apply Now
                      </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
     {/* modal */}
    {modal && (
      
          
        <div className="modal">
          <div onClick={closePopup} className="overlay"></div>
        
          <div className="modal-content">
          {isSubmitting && <div className="submitting-spinner">
          <Spinner/>
          </div>}
          <div className="modal-header">
              <h2>Send a Resume</h2>
              <button className="close-modal" onClick={closePopup} >
              <AiOutlineClose/>
              </button>            
          </div>

         
          
          <div className="modal-body">
          <form className="apply-form" onSubmit={handleSubmit(sendEmail)}>
                  <div className="left"> 
                  <input type="text"
                     name="name"
                     placeholder="Full Name *"
                     {...register("name")}
                    
                     style={{borderColor: errors.name?.message ? "#ff0000": "#A0A0A0"}} />
                      <span>{<p className ="popUpErrors_valid" style={{color: "#ff0000"}}>{errors.name?.message}</p>} </span>
                  </div>
                  <div className="right"> 
                  <input type="email"
                     name="email"
                     placeholder="Email Address *"
                     {...register("email")}
                   
                   
                     style={{borderColor: errors.email?.message ? "#ff0000": "#A0A0A0"}} />
                      <span>{<p className ="popUpErrors_valid" style={{color: "#ff0000"}}>{errors.email?.message}</p>} </span>

                  
                  </div>
                  <div className="left"> 
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone *"
                      {...register("phone")}
                      minLength={7}
                      maxLength={13}
                     
                      
                      style={{borderColor: errors.phone?.message ? "#ff0000": "#A0A0A0"}}
                    
                    />
                     
                     <span>{<p className ="popUpErrors_valid" style={{color: "#ff0000"}}>{errors.phone?.message}</p>} </span>
                  </div>
                  <div className="right"> 
                    <input
                      type="text"
                      name="address"
                      placeholder="Location *"
                      {...register("location")}
                   
                      
                      style={{borderColor: errors.location?.message ? "#ff0000": "#A0A0A0"}}

                     
                    
                    />
                    <span>{<p className ="popUpErrors_valid" style={{color: "#ff0000"}}>{errors.location?.message}</p>} </span>
                  </div>
                  <div className="center"> 
                    <textarea
                      type="text"
                      name="addinfo"
                      placeholder="Additional Info"
                      {...register("addinfo")}

                    />
                   
                  </div>
                  <div className="resume-box">
                    <div className="res-div">
                      <label className="upload-resume"  style={{borderColor: validLabel1 ? "#ff0000": "#A0A0A0"}} >    
                        { isloading ? <Spinner /> :

                              <>
                              <input type="file" name="file1" size="60" onChange={onAttachmentsSubmit} accept=".pdf,.doc,.docx,jpg,.png,.jpeg"     />
                              
                              {changeIcon ? <FaFilePdf/> :  <h3>Resume / CV *</h3>}
                              <h5>{changeFirstBoxName}</h5>
                              
                              </>
                          }
                          
                      </label>
                     { validLabel1 && <span className="res-d1" style={{color: "#ff0000"}}>Please Upload Your Resume </span>}
                      </div>
                      <div className="res-div" style={{marginLeft: "20px"}}>
                      <label className="upload-resume"  style={{borderColor: validLabel2 ? "#ff0000": "#A0A0A0"}} >                      
                      { isloading2 ? <Spinner /> :

                      <>
                      <input type="file" name="file2" size="60" onChange={onAttachmentsSubmit2} accept=".pdf,.doc,.docx,jpg,.png,.jpeg"  />

                      {changeIcon2 ? <FaFilePdf/> :  <h3>Cover Letter *</h3>}
                      <h5>{changeFirstBoxName2}</h5>

                      </>
                         
                      }
                      </label>
                      { validLabel2 && <span className="res-d1" style={{color: "#ff0000"}}>Please Upload Your Cover Letter </span>}
                      </div>
                   </div>
                  <div className="form-btn">
                      <button onClick={closePopup} className="cancel-btn" type="submit">Cancel</button>
                    <button type="submit">Submit</button>
                  </div>
                </form>
          </div>
          
        </div>
          
        </div> 
      )}
 
  </Layout>
  );
};

export default Career;


